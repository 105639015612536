<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Отчет об успеваемости и качеству знаний учащихся 1-11 классов
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4" v-if="showMainDepartment()">
                  <b-form-group>
                    <template v-slot:label>
                      Район
                    </template>
                    <b-form-select
                        v-model="filter.regionId"
                        :options="regions"
                        @change="reloadSchools"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Школа
                    </template>
                    <b-form-select
                        v-model="filter.schoolId"
                        :options="schools"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Четверть
                    </template>
                    <b-form-select
                        v-model="filter.quarter"
                        :options="quarterOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>               
              </div>
              <b-button type="button" @click="reloadData" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
              >{{
                  $t("common.filter")
                }}
              </b-button>

              <b-button type="button" @click="exportData" variant="primary" style="margin-left: 10px"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
              >Экспорт</b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th rowspan="2">Школа</th>
                <th rowspan="2">Класс</th>
                <th rowspan="2">Кол-во уч-ся на конец четверти</th>
                <th rowspan="2">Аттестовано</th>
                <th colspan="4">Не аттестовано</th>
                <th colspan="4">Успевают</th>
                <th rowspan="2">% качества</th>
                <th rowspan="2">% успеваемости</th>
                <th rowspan="2">% СОУ</th>
              </tr>
              <tr>
                <th>Всего</th>
                <th>1-2 кл.</th>
                <th>10-11 кл.</th>
                <th>Другие причины</th>
                <th>«5»</th>
                <th>«4»</th>
                <th>«3»</th>
                <th>«2»</th>
              </tr>
              </thead>
              <tbody v-for="(school, sk) in data.items" :key="sk">
              <tr v-for="(item, k) in school.reports" :key="k">
                <td>{{ item.schoolName }}</td>
                <td>{{ item.unitName }}</td>
                <td>{{ item.periodEndStudentsCount }}</td>
                <td>{{ item.certified }}</td>
                <td>{{ item.unCertifiedAll }}</td>
                <td>{{ item.unCertified12 }}</td>
                <td>{{ item.unCertified1011 }}</td>
                <td>{{ item.unCertifiedOther }}</td>
                <td>{{ item.certified5 }}</td>
                <td>{{ item.certified4 }}</td>
                <td>{{ item.certified3 }}</td>
                <td>{{ item.certified2 }}</td>
                <td>{{ item.quality.toFixed(2) }}</td>
                <td>{{ item.progress.toFixed(2) }}</td>
                <td>{{ item.sou.toFixed(2) }}</td>
              </tr>
              </tbody>
              
              <tbody>

              <!--                 
                         <tr v-for="(item, k) in data" :key="k">                
                              <td>{{ item.unitName }}</td>
                              <td>{{ item.periodEndStudentsCount }}</td>
                              <td>{{ item.certified }}</td>
                              <td>{{ item.unCertifiedAll }}</td>
                              <td>{{ item.unCertified12 }}</td>
                              <td>{{ item.unCertified1011 }}</td>
                              <td>{{ item.unCertifiedOther }}</td>
                              <td>{{ item.certified5 }}</td>
                              <td>{{ item.certified4 }}</td>
                              <td>{{ item.certified3 }}</td>
                              <td>{{ item.certified2 }}</td>
                              <td>{{ item.quality.toFixed(2) }}</td>
                              <td>{{ item.progress.toFixed(2) }}</td>
                              <td>{{ item.sou.toFixed(2) }}</td>
                            </tr>-->
<!--              <tr>
                <td>Всего</td>
                <td></td>
                <td>{{ data.sum.periodEndStudentsCount }}</td>
                <td>{{ data.sum.certified }}</td>
                <td>{{ data.sum.unCertifiedAll }}</td>
                <td>{{ data.sum.unCertified12 }}</td>
                <td>{{ data.sum.unCertified1011 }}</td>
                <td>{{ data.sum.unCertifiedOther }}</td>
                <td>{{ data.sum.certified5 }}</td>
                <td>{{ data.sum.certified4 }}</td>
                <td>{{ data.sum.certified3 }}</td>
                <td>{{ data.sum.certified2 }}</td>
                <td>{{ data.sum.quality.toFixed(2) }}</td>
                <td>{{ data.sum.progress.toFixed(2) }}</td>
                <td>{{ data.sum.sou.toFixed(2) }}</td>
              </tr>-->
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import DictionariesService from "@/common/dictionaries.service";
import ApiService from "@/common/api.service";
import {mapGetters} from "vuex";

export default {
  name: "EducationDepartmentEducationProgressReport",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter:{
        quarter: 1,
        regionId: 0,
        schoolId: 0,
        educationYear: DictionariesService.currentYear(),
      },
      data:{},
      quarterOptions:DictionariesService.quarterOptions(),
      educationYears: DictionariesService.educationYearOptions(),
      loading: false,
      regions:[],
      schools:[],
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  mounted() {
    ApiService.querySecured("dictionaries/regions", {}).then(({data}) => {
      this.regions = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("dictionaries/schools", {}).then(({data}) => {
      this.schools = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
  },
  methods: {
    reloadData(){
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("educationDepartment/eduProgressReportDepartment", this.filter).then(({data}) => {
        $this.data = data;
        for (let i in $this.data.items){
          let reports = [];
          let ii  = $this.data.items[i];
          ii.report.groups14Sum.schoolName = ii.schoolName;
          reports.push(ii.report.groups14Sum);
          reports.push(ii.report.groups59Sum);
          reports.push(ii.report.groups1011Sum);
          ii.report.groups1To11Sum.unitName = "Всего";
          reports.push(ii.report.groups1To11Sum);

          ii.reports = reports;
        }
        
        
      }).catch(({response}) => {
        console.log(response);
      }).finally(function (){
        $this.loading = false;
      });
    },
    reloadSchools(){
      ApiService.querySecured("dictionaries/schools",
          {params: {regionId: this.filter.regionId}})
          .then(({data}) => {
            data.dictionary.unshift({ value: 0, text: '' });
            this.schools = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    exportData(){
      let filters = this.filter;
      let $this = this;
      $this.loading = true;
      filters['export'] = true;
      ApiService.downloadSecured("educationDepartment/eduProgressReportDepartment", filters)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Отчет.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({response}) => {
            console.log(response);
          }).finally(function () {
              $this.loading = false;
      });
    },
    showMainDepartment(){
      return this.currentUser.role == 'ROLE_MAIN_EDUCATION_DEPARTMENT';
    }
  }
};
</script>
